/* legal
-------------------------------------------------*/
.legal {
    @extend %space;
    background: $colorBg;
    .row {
        @include breakpoint(small) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    [class^="col-"] {
        @include breakpoint(small) {
            display: flex; 
        }
    }
    .icon {
        font-size: 1.5rem;
        color: $colorPrimary;
    }
    h4 {
        margin-bottom: 0;
    }
}