/* TICKETS
-------------------------------------------------*/
.tickets {
  margin-bottom: $space;
  @extend %space;
  background: $colorBg;
  h4,
  h2 {
    padding: 10px 0 0;
    margin: 0;
  }
  a {
    text-decoration: underline;
  }
  h2 {
    padding-bottom: 2rem;
  }
  hr {
    border-color: darken($colorBg, 30%);
  }
  .col-sm-4 {
    overflow: hidden;
  }
}
