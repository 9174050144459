/* AUDITIONS
-------------------------------------------------*/
.auditions {
    @extend %space;
    h4 {
        padding: 10px 0 0;
        margin: 0;
    }
    a {
        text-decoration: underline;
    }
}