/* ABOUT
-------------------------------------------------*/
.about {
  margin-bottom: $space;
  @extend %space;
  color: $white;
  background: lighten($colorPrimary, 2%);
  h2,
  h4,
  h3 {
    color: $white;
  }
  h4 {
    padding: 10px 0 0;
    margin: 0;
  }
  a {
    color: $white;
    text-decoration: underline;
  }
}
